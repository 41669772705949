
@import "../../assets/sass/variables";

.login-wrapper {
    input[type=text], 
    input[type=email], 
    input[type=tel], 
    select, 
    textarea,
    .ant-input-password{
        padding: rem(10) rem(14);

        .ant-input-password-icon{
            color: $colorsecondary;
        }

        input[type=text]{
            padding: 0;
            height: auto;
            border: 0;
        }
    }

    .ant-form{
        input[type=password]{
            padding: 0;
            height: auto;
        }
        .ant-form-item{
            margin-bottom: 0;
        }
    }

    .form-row {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background: $colorprimary;
    }


    .login-form-wrapper {
        width: rem(450);
        padding: rem(25) rem(16);
        border-radius: rem(10);
        background: $colorwhite;
        box-shadow: -1px 2px 5px 1px rgba(0,0,0,0.33);
        -webkit-box-shadow: -1px 2px 5px 1px rgba(0,0,0,0.33);
        -moz-box-shadow: -1px 2px 5px 1px rgba(0,0,0,0.33);
    }
    
    .app-logo {
        text-align: center;
        img{
            max-width: rem(192);
        }
        h1{
            color: $heading-color;
            margin: rem(30) 0;
            font-size: rem(24);
            letter-spacing: -0.1px;
        }
    }
    .subheading-text{
        color: $gray-200;
        font-size: rem(12);
        text-align: center;
    }
    .needHelpTxt {
        display: flex;
        justify-content: space-around;
         margin-inline: rem(25);
    
        a , i{
            color: $gray-100;
            font-size: rem(13);
        }
        a:hover{
            border-bottom-width: rem(1.5);
            border-bottom-color:$colorskyblue ;
            border-bottom-style: solid ;
            color: $colorskyblue;

            
        }
    }
    
    .forget-password-link{
        font-size: rem(14);
        text-decoration: none;
        color: $colorblack;
        a{
            color: $colorskyblue;
            font-weight: bold;
                
        }
        a:hover{
            color: $colorsecondary;
        }

        

    }

    

    .help-text{
        a, span{
            color: $gray-100;
            font-size: rem(14);
            u{
                color: $gray-200;
            }
        }
        u:hover{
            color:$colorskyblue
        }
        a:hover{
            color:$colorskyblue
        }

    }
    
    .ant-alert {
        width: 100%;
        margin-bottom: rem(20) !important;
    }
    
    .process-text{
        .message-text{
            color: $colorprimary;
            font-size: rem(19);
        }
        .confirm-ico{
            font-size: rem(100);
            color: $colorsecondary;
        }
        .help-ico{
            font-size: rem(100);
            color: $colorsecondary;
        }
    }
}