body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    font-size: rem(16);
    background: $colorwhite;
}

img{
    max-width: 100%;
    vertical-align: middle;
}

a{
    color: $colorprimary;
    &:hover{
        opacity: 0.9;
        color: $colorprimary;
    }
}

input[type=text], 
input[type=email], 
input[type=tel],
input[type=password], 
select, textarea,
.ant-input-password,
// .ant-picker,
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    padding: rem(10) rem(14);
    border-radius: 3px;
    font-weight: 400;
    border: 1px solid $border-color;
    width: 100%;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #70797D;
        font-size: rem(14);
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: #70797D;
        font-size: rem(14);
      }
    &:-ms-input-placeholder { /* IE 10+ */
        color: #70797D;
        font-size: rem(14);
      }
    &:-moz-placeholder { /* Firefox 18- */
        color: #70797D;
        font-size: rem(14);
    }
}



.ant-picker{
    padding: 0 rem(14) 0 0;
    border-radius: 3px;
    font-weight: 400;
    border: 1px solid $border-color;
    width: 100%;

    .ant-picker-input > input{
        line-height: normal;
        padding: rem(10) rem(14);

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #70797D;
            font-size: rem(14);
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: #70797D;
            font-size: rem(14);
          }
        &:-ms-input-placeholder { /* IE 10+ */
            color: #70797D;
            font-size: rem(14);
          }
        &:-moz-placeholder { /* Firefox 18- */
            color: #70797D;
            font-size: rem(14);
        }
    }
}


//Generic Style
.table-page {
    padding-bottom: 20px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before{
    display: none !important;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after{
    display: inline-block;
    margin-right: rem(5);
    margin-left: rem(5);
    color: $white-100;
    font-size: rem(12);
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

label.ant-form-item-required:after{
    display: inline-block;
    margin-right: rem(5);
    margin-left: rem(5);
    color: #ff4d4f;
    font-size: rem(14);
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.form-group{
    .ant-form-item-label{
        label{
            color: $colorcontent;
        }
    }
}

.ant-form-item-has-error{
    input[type=text], 
    input[type=email], 
    input[type=tel], 
    select, textarea, 
    .ant-input-password,
    .ant-picker,
    .ant-select-selector{
        border-color: $colorred !important;
    }
}

.ant-picker{
    cursor: pointer;
}

.ant-btn{
    font-size: rem(14);
    color: #333;
    border-radius: rem(7);
    border: 0;
    text-transform: capitalize;
    min-height: rem(38);
    box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.17);
    -webkit-box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.17);
    -moz-box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.17);

    &:hover, &:focus{
        opacity: 0.85;
        outline: none;
    }

    i{
        font-size: rem(14);
    }

    &.btn-block{
        width: 100%;
    }

    &.btn-icon{
        i{
            font-size: rem(20);

            &.icon-content_copy{
                font-size: rem(16);
            }
        }
    }
}

.ant-btn-primary{
    background: $colorsecondary;
    color: $colorwhite;
    &:hover, &:focus{
        background: $colorsecondary !important;
    }
}

.ant-btn-secondary{
    background: $colorprimary;
    color: $colorwhite;
    &:hover, &:focus{
        background: $colorprimary;
        color: $colorwhite !important;
    }
}

.ant-btn-white{
    background: $colorwhite;
    color: $colorsecondary;
    border: 1px solid $colorsecondary;
    &:hover, &:focus{
        background: $colorwhite !important;
        color: $colorsecondary !important;
    }
}

.ant-btn-purple{
    font-size: rem(16);
    background: $color-purple;
    color: $colorwhite;
    &:hover, &:focus{
        background: $color-purple-100;
        color: $colorwhite !important;
    }
}

.ant-btn-blue{
    font-size: rem(16);
    background: $colorskyblue;
    color: $colorwhite;
    &:hover, &:focus{
        background: $colorskyblue;
        color: $colorwhite !important;
    }
}

.ant-btn-blue-outline{
    font-size: rem(16);
    border: 1px solid $colorskyblue;
    color: $colorskyblue;
    background: transparent;
    box-shadow: none;
    &:hover, &:focus{
        background: $colorskyblue;
        color: $colorwhite !important;
    }
}


.ant-btn-premium{
    background: #DEE2E6;
    color: #444D52;
    &:hover, &:focus{
        background: #DEE2E6;
        color: #444D52;
    }
}

.ant-btn-primary[disabled], 
.ant-btn-primary[disabled]:hover, 
.ant-btn-primary[disabled]:focus, 
.ant-btn-primary[disabled]:active{
    background-color: $colorsecondary;
    opacity: 0.7;
    color: rgba(255,255,255,0.85);
}


.ant-space {
    display: inline-flex;
    background: rgba(255,255,255,0.6);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    z-index: 9999;

    .ant-space-item{
        .ant-spin-spinning{
            .ant-spin-dot-spin{
                transform: rotate(0deg);
                -webkit-animation: none;
                animation: none;
                .ant-spin-dot-item{
                    display: none;
                }
                &::before{
                    content: url(../images/spinner-o.gif);
                }
            }
        }
    }
}

.ant-layout{
    background: $white-200;
}


//Overrides


.ant-input:focus,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused{
    box-shadow: none !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
    border-color: $border-color;
}

.ant-form{

    input[type=text], 
    input[type=email], 
    input[type=tel],
    input[type=password], 
    input[type=number],
    select, textarea,
    // .ant-picker,
    // .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input-password
    {
        padding: rem(7) 0;
        border-radius: 0;
        font-weight: 400;
        border: 1px solid #d6d6d6;
        background-color: $colorwhite !important;
        width: 100%;
        height: rem(40);
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #929699;
            font-size: rem(14);
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: #929699;
            font-size: rem(14);
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: #929699;
            font-size: rem(14);
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: #929699;
            font-size: rem(14);
        }
    }

    input[type=password]{
        border: 0;
    }

    textarea{
        min-height: rem(140);
    }

    .ant-picker{
        padding: 0 rem(15) 0 0;
        border-radius: rem(35);
        font-weight: 400;
        border: 1px solid $border-color;
        background-color: $colorwhite !important;
        width: 100%;
        height: rem(40);

        .ant-picker-input > input{
            line-height: normal;
            padding: rem(10) rem(14);
    
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #929699;
                font-size: rem(14);
            }
            &::-moz-placeholder { /* Firefox 19+ */
                color: #929699;
                font-size: rem(14);
            }
            &:-ms-input-placeholder { /* IE 10+ */
                color: #929699;
                font-size: rem(14);
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: #929699;
                font-size: rem(14);
            }
        }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        padding: 0 rem(14);
        border-radius: rem(35);
        font-weight: 400;
        border: 1px solid $border-color;
        background-color: #fff !important;
        width: 100%;
        height: rem(40);
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        padding: rem(5) rem(14) rem(5) !important;
        height: rem(40);

        .ant-select-selection-placeholder{
            color: #929699;
            font-size: rem(14);
        }

        .ant-select-selection-search-input{
            width: 100%;
            height: 100%;
        }
    }

    .ant-select{
        .ant-select-arrow {
            font-size: rem(15);
            right: rem(15);
        }
    }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $border-color;
    box-shadow: 0 0 0 2px rgba(211, 213, 215, 0.2);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $border-color;
}

.form-wrapper-no-margin{
    .ant-form-item{
        margin: 0;
    }
}


//Headings

.main-head-jumbo{
    font-size: rem(72);
    border-bottom: 2px solid $border-color;
    margin: 0;
    padding-bottom: rem(16);
}

.main-head{
    margin: 0;
    text-transform: uppercase;
    font-size: rem(30);
    display: flex;
    align-items: center;

    .icon-img{
        height: rem(40);
        margin-right: rem(16);
    }
    .icon-img-sm{
        margin-right: rem(16);
        height: rem(30);
    }
}

.main-sub-head{
    font-weight: 700;
}

.title-blue-bar{
    background: $color-purple-200;
    color: $colorwhite;
    font-size: rem(20);
    padding: rem(4) rem(20) rem(6);
    margin: 0 0 rem(10);
    box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.17);
    -webkit-box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.17);
    -moz-box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.17);
}

//card

.shadow-card{
    box-shadow: 1px 3px 8px 1px rgb(158 154 154 / 46%);
    -webkit-box-shadow: 1px 3px 8px 1px  rgb(158 154 154 / 46%);
    -moz-box-shadow: 1px 3px 8px 1px rgba(158,154,154,0.46);
    border-radius: rem(50);
    padding: rem(20);
}

.dashboard-card{
    background-color: white;
    .title{
        font-size: rem(22);
        font-weight: 700;
    }
    img{
        max-width: rem(100);
        max-height: rem(100);
    }
    .desc {
        font-style: italic;
        color: $colorblack
    }

    &:hover{
        .find-more{
            opacity: 1;
        }
    }

    .ico-shortlist-main{
        font-size: rem(88);
        color: $color-purple;
    }
}

.is-relative{
    position: relative;
}

.over-flow-hide{
    overflow: hidden;
}

.find-more {
    background: #e2e2e2a8;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: start;
    justify-content: center;
    transition: all ease .3s;
    opacity: 0;

    .main-link{
        color: $colorblack;
        font-weight: 700;
        font-size: rem(18);
    }
}

//Notification

.ant-notification-notice {
    display: flex;
    align-items: center;
    i{
        font-size: rem(16);
        top: rem(16);
        color: $colorwhite;
    }

    &.notification-success{
        background: #4cbb17;
        border: 1px solid #4cbb17;
    }
    &.notification-error{
        background: #e32636;
        border: 1px solid #e32636;
    }
    &.notification-warning{
        background: #ffe58f;
        border: 1px solid #ffe58f;
    }
    &.notification-info{
        background: #91d5ff;
        border: 1px solid #91d5ff;
    }

    .ant-notification-notice-message,
    .ant-notification-notice-description,
    .ant-notification-notice-close-x{
        color: $colorwhite;
    }

    .ant-notification-notice-close{
        top: rem(7);
        right: rem(7);
    }
}

.custom-table{
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    border-bottom-left-radius: rem(10);
    border-bottom-right-radius: rem(10);

    .table-title{
        color: $colorwhite;
        padding: rem(5) rem(15);
        margin: 0;
        &.title-blue{
            background: $colorprimary;
        }
    }

    .ant-table-wrapper {
        .ant-table-tbody {
            >tr {
                >td {
                    border-bottom: 0;
                    color: #191a3a;
                    font-size: rem(16);

                    &.has-error{
                        color: $colorred;
                        text-decoration: underline;
                    }

                    .icon-primary{
                        color: $color-purple;
                        font-size: rem(20);

                        &:hover{
                            color: $color-purple-100;
                        }
                    }
                    .icon-secondary{
                        color: $colorsecondary;
                        font-size: rem(20);

                        &:hover{
                            color: $colorsecondary;
                        }
                    }
                    .icon-del{
                        color: $colorred;
                        font-size: rem(20);
                        &:hover{
                            color: #ff0523bd;
                        }
                    }
                }
            }
        }
        .ant-table-thead {
            >tr {
                >th , td {
                    background: $colorwhite;
                    border-bottom: 0;
                    font-weight: 700;
                    font-size: rem(16);
                    color: $colorblack;
                    &::before{ display: none;}
                }
            }
        }
    }

    .ant-pagination{
        background: $colorwhite;
        text-align: right;
        padding: rem(16);

        .ant-pagination-item{
            border-radius: 100%;
            margin-inline-end: rem(5);

            a{
                color: #b3b2b2;
                font-weight: 700;
            }

            &.ant-pagination-item-active {
                border: 0;
                background-color: $color-purple;
                a{
                    color: $colorwhite;
                }
            }
        }

        .ant-pagination-item-link{
            border-radius: 100%;
            color: #b3b2b2;
        }


    }

}

.table-striped-rows tr:nth-child(2n+1) td {
    background-color: #F2F5FF;
}
.meesagesModal{
    text-align: center;
    
}
.input-tool-tip{
    font-weight: bold;
    .info{
        color:#b98bed;
        cursor: pointer;
    }
    .info-text{
        color: $gray-100;
    }
}
.is-pointer{
    cursor: pointer;
}

.cancel-button {
    box-shadow: none;
            background: transparent !important;
            color: $gray-200 !important;

            &:hover{
                color: $colorsecondary !important;
                background: transparent !important;
            }
  }

.ant-modal-root {
    .ant-modal-mask{
        background-color: rgb(0 0 0 / 8%);
    }
    .ant-modal{
        top: 50%;
        transform: translateY(-50%);

        .ant-modal-content{
            border-radius: 0;
            p{
                font-weight: 500;
                font-size: rem(16);
            }
        }

        .ant-modal-close{
            i{
                color: $color-purple;
            }
            
        }
    }
}

.ant-modal-confirm {
    .ant-modal-confirm-btns{
        text-align: center;

        .ant-btn{
            box-shadow: none;
            background: transparent !important;
            color: $color-purple;

            &:hover{
                color: $colorsecondary;
                background: transparent !important;
            }

            &:focus-visible{
                outline: none;
            }
        }
    }
}

.box-field{

    .field-wrap{
        padding: rem(16);
        border-radius: rem(8);
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
        -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
        background: $colorwhite;
        margin-bottom: rem(24);

        label{
            color: $colorblack;
            font-size: rem(14);
            font-weight: 700;
        }

        .ant-form-item-label{
            padding: 0;
        }
    }

    .ant-form-item{
        margin: 0;

        input[type=text], 
        input[type=email], 
        input[type=tel],
        input[type=password], 
        input[type=number],
        select, textarea,
        // .ant-picker,
        // .ant-select:not(.ant-select-customize-input) .ant-select-selector,
        .ant-input-password
        {
            border: 0;
            border-radius: 0;
            box-shadow: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            padding: 0;
            height: auto;
            border-bottom: 1px solid $border-color;
    
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: $colorblack;
                font-size: rem(14);
                font-weight: 700;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                color: $colorblack;
                font-size: rem(14);
                font-weight: 700;
            }
            &:-ms-input-placeholder { /* IE 10+ */
                color: $colorblack;
                font-size: rem(14);
                font-weight: 700;
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: $colorblack;
                font-size: rem(14);
                font-weight: 700;
            }
        }
    
        input[type=password]{
            border: 0;
        }
    
        textarea{
            min-height: rem(160);
            padding: 0;
            resize: none;
            margin-top: rem(10);
            height: rem(250);
        }
    
        .ant-picker{
            padding: 0 rem(15) 0 0;
            border-radius: rem(35);
            font-weight: 400;
            border: 1px solid $border-color;
            background-color: $colorwhite !important;
            width: 100%;
            height: rem(40);
    
            .ant-picker-input > input{
                line-height: normal;
                padding: rem(10) rem(14);
        
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: #929699;
                    font-size: rem(14);
                }
                &::-moz-placeholder { /* Firefox 19+ */
                    color: #929699;
                    font-size: rem(14);
                }
                &:-ms-input-placeholder { /* IE 10+ */
                    color: #929699;
                    font-size: rem(14);
                }
                &:-moz-placeholder { /* Firefox 18- */
                    color: #929699;
                    font-size: rem(14);
                }
            }
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector{
            font-weight: 400;
            padding: rem(20) 0 0;
            border-radius: 0;
            font-weight: 400;
            border: 0;
            background-color: $colorwhite !important;
            width: 100%;
            height: auto;
        }

        .ant-select-focused:where(.css-dev-only-do-not-override-diro6f).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
            border-color: transparent;
            box-shadow: none;
            outline: 0;
        }
    
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            width: 100%;
            border: 0;
            border-radius: 0;
            box-shadow: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            padding: 0 !important;
            height: auto;
    
            .ant-select-selection-placeholder{
                color: $colorblack;
                font-size: rem(14);
                font-weight: 700;
            }
    
            .ant-select-selection-search-input{
                width: 100%;
                height: 100%;
            }
        }
    
        .ant-select{
            .ant-select-arrow {
                font-size: rem(15);
                right: rem(15);
            }
        }

        .ant-form-item-explain-error{
            margin: 0;
        }
    }

    .file-dd-wrap,
    .tag-selector-wrap{
        border-radius: rem(8);
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
        -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
        padding: rem(15) rem(15);
        background: $colorwhite;

        label{
            color: $colorblack;
            font-size: rem(14);
            font-weight: 700;
        }
        
        .tags-input{
            border-bottom: 1px solid $border-color
        }

        .ant-upload-wrapper {
            .ant-upload-drag{
                border: 1px solid $colorcontent;
                border-radius: 0;
                background: $colorwhite;
                text-align: center;

                .ant-upload-hint{
                    margin: 0;
                }

                .ant-upload{
                    padding: rem(16);
                    // height: rem(100) !important;
                    // display: inline-block !important;
                }
            }

            .ant-upload-list-item-actions{
                .ant-btn{
                    box-shadow: none;

                    .anticon-delete{
                        svg{
                            fill: $colorred;
                        }
                        
                    }
                }
            }
        }

        .ant-select-multiple {
            .ant-select-selection-item{
                border-radius: rem(50);
                border: 0;
                background: $color-purple;
                margin-inline-end: rem(10);
                padding-inline-start: rem(12);
                padding-inline-end: rem(7);

                .ant-select-selection-item-content,
                .ant-select-selection-item-remove{
                    color: $colorwhite;
                }
            }
        }
    }

    .label-md{
        color: $colorblack;
        font-weight: 500;
    }

    .frame-field{
        .ant-form-item{
            margin: 0;
    
            input[type=text], 
            input[type=email], 
            input[type=tel],
            input[type=password], 
            input[type=number],
            select, textarea,
            .ant-input-password
            {
                border: 1px solid $border-color;
                padding: 0 rem(10);
                min-height: rem(35);
            }
            
            textarea{
                border: 1px solid $border-color;
            }
    
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector{
            padding: rem(3) rem(10);
            border: 1px solid $border-color !important;
        }

        .ant-select-multiple {
            .ant-select-selection-item{
                border-radius: rem(50);
                border: 0;
                background: $color-purple;
                margin-inline-end: rem(10);
                padding-inline-start: rem(12);
                padding-inline-end: rem(7);

                .ant-select-selection-item-content,
                .ant-select-selection-item-remove{
                    color: $colorwhite;
                }
            }
        }
    }
}

.error-in-ranking {
    color: red !important;
    text-decoration: underline;
} 

.detail-bars-wrap {
    max-height: calc(100vh - rem(290));
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: rem(10);

    &::-webkit-scrollbar {
        width: rem(5);
    }
      
      /* Track */
    &::-webkit-scrollbar-track {
        background: #e1cff4; 
        border-radius: rem(5);
    }
       
      /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #995ae2; 
        border-radius: rem(5);
    }
      
      /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #995ae2; 
    }
}

.detail-bar{
    border: 2px solid #a2a3a6;
    border-radius: rem(10);
    min-height: rem(50);

    span{
        font-weight: 500;
        font-size: rem(13);

        &.data-lg{
            font-size: rem(16);
            font-weight: 400;
            color: #191a3a;
        }
        &.view-txt{
            color: $colorblack;
        }
    }
    .icon-sm{
        font-size: rem(11);
    }

    a{
        font-weight: 500;
        font-size: rem(13);

        &.data-lg{
            font-size: rem(16);
            font-weight: 400;
            color: #191a3a;
        }
        &.view-txt{
            color: $colorblack;
        }
        .icon-sm{
            font-size: rem(11);
        }
    }

    .icon-primary{
        color: $color-purple;
        font-size: rem(20);
        &:hover {
            cursor: pointer;
            color: $color-purple-100;
        }
    }
    .icon-secondary{
        color: $colorblack;
        font-size: rem(20);
        &:hover {
            cursor: pointer;
        }
    }
    .notes, .icon-ic-mode-edit-18px, .view-txt {
        &:hover {
            cursor: pointer;
            color: $color-purple-100;
        }
    }
}

.ant-modal-confirm-content {
    width: 100%;
}

.opacity-20 {
    opacity: 0.2;
}

.RichEditor-editor{
    border-top: 0 !important;
}

.truncate-txt-sm{
    text-align: left;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
}

.text-left {
    text-align: left;
}

.is-link-icon{
    cursor: pointer;
    color: $color-purple !important;
}


.ant-radio-group-solid :where(.css-dev-only-do-not-override-diro6f).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background-color: $color-purple !important;
    border-color: $color-purple !important;
     ;
    &:hover{
        background-color: $color-purple-100 !important;
        border-color: $color-purple-100 !important;
    }
    &:before{
        background-color:$color-purple !important
    }
}
.ant-radio-group-solid :where(.css-diro6f).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    color: #fff;
    background-color: $color-purple !important;
    border-color: $color-purple !important;
     ;
    &:hover{
        background-color: $color-purple-100 !important;
        border-color: $color-purple-100 !important;
    }
    &:before{
        background-color:$color-purple !important
    }
}