@use "sass:math";

$html-font-size: 16px;

@function stripUnit($value) {
    @return math.div($value, $value * 0 + 1);
}

@function rem($pxValue) {
    @return #{math.div(stripUnit($pxValue), stripUnit($html-font-size))}rem;
}

//Color Vars

$colorwhite: #fff;
$colorblack: #000;
$colorprimary: #6065F1;
$colorsecondary: #D20C65;
$colorcontent: #374254;
$border-color: #d6d6d6;
$heading-color: #191A3A;
$colorred: #ff0523;
$colorlink: #5bcef9;
$colorskyblue:#41A9E7;

$white-100: #b8b8b8;
$white-200: #F2F5FF;
$gray-100: #beb3b4;
$gray-200: #8d8d8d;

$color-purple: #a460f1;
$color-purple-100: #B98BED;
$color-purple-200: #7478f0;